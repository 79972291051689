import React from 'react';
import ReactDOM from 'react-dom';
import { CHANNEL_TYPES } from './constants';
import { Linkees as Links } from './components/Linkees';

import './css/normalize.css';

const items = [
  {
    title: 'Website',
    subtitle: 'Learn a bit more about me!',
    type: CHANNEL_TYPES.WEBSITE,
    link: 'https://narcisse.dev', //your personal website or portfolio  link
  },
  {
    title: 'GitHub',
    subtitle: '@darkfadr | 🏡 to all my open-source projects',
    type: CHANNEL_TYPES.GITHUB,
    link: 'https://github.com/heysagnik', //Github Profile link
  },
  {
    title: 'Instagram',
    subtitle: '@darkfadr |  Shots of my life 🙂',
    type: CHANNEL_TYPES.INSTAGRAM,
    link: 'https://instagram.com/heysagnik', //instagram profile link
  },
  {
    title: 'Twitter',
    subtitle: '@_darkfadr | 😉',
    type: CHANNEL_TYPES.TWITTER,
    link: 'https://twitter.com/_darkfadr', // twitter profile link
  },
  {
    title: 'LinkedIn',
    subtitle: 'Know more about my profession 👨‍💼',
    type: CHANNEL_TYPES.LINKEDIN,
    link: 'https://www.linkedin.com/in/anarcisse/', // linkedin
  },
];

ReactDOM.render(
  <React.StrictMode>
    <Links name="Ashley Narcisse" headerAvatar="https://www.ashleynarcisse.com/assets/img/bg.webp" cardItems={items} />
  </React.StrictMode>,
  document.getElementById('root')
);
